<template>
  <div>
    <Back title="注册" />
    <div :class="$style.content">
      <img :class="$style.logo" src="@/assets/img/logo.png" />
      <div :class="$style.filedWrap">
        <p :class="$style.label">手机号</p>
        <van-field
          :class="$style.field"
          v-model="mobile"
          type="tel"
          maxlength="11"
          placeholder="请输入手机号"
        />
      </div>
      <div :class="$style.filedWrap">
        <p :class="$style.label">验证码</p>
        <div :class="$style.codeWrap">
          <van-field
            :class="[$style.field, $style.code]"
            v-model="code"
            placeholder="请输入验证码"
          >
          </van-field>
          <Code :mobile="mobile" type="1" />
        </div>
      </div>
      <div :class="$style.filedWrap">
        <p :class="$style.label">设置密码</p>
        <van-field
          :class="$style.field"
          v-model="pwd"
          type="password"
          placeholder="请输入6-20位登录密码"
        />
      </div>
      <div :class="$style.filedWrap">
        <p :class="$style.label">确认密码</p>
        <van-field
          :class="$style.field"
          v-model="confirmPwd"
          type="password"
          placeholder="请再次输入6-20位登录密码"
        />
      </div>
      <van-button
        :class="$style.btn"
        round
        block
        @click="handleRegister"
        :color="canSubmit ? '#212121' : '#707070'"
      >
        立即注册</van-button
      >
      <div :class="$style.clause">
        <van-checkbox v-model="checked" :class="$style.checked"
          >我已同意
          <span :class="$style.clauseItem" @click.stop="handle2Agreement"
            >《用户协议》</span
          >和
          <span :class="$style.clauseItem" @click.stop="handle2Privacy"
            >《隐私政策》</span
          >
        </van-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import Code from "@/components/Code";
import { register } from "@/api/";
import { md5 } from "@/utils/util";
export default {
  components: {
    Back,
    Code,
  },
  computed: {
    canSubmit() {
      return this.mobile && this.code && this.pwd && this.confirmPwd;
    },
  },
  data() {
    return {
      mobile: "",
      code: "",
      pwd: "",
      confirmPwd: "",
      checked: false,
    };
  },
  methods: {
    handleRegister() {
      if (!this.canSubmit) return;
      if (!this.checked) {
        this.$toast("请先阅读并同意相关协议条款");
        return;
      }
      const data = {
        mobile: this.mobile,
        sms_code: this.code,
        password: md5(this.pwd),
        password_confirmation: md5(this.confirmPwd),
      };
      register(data)
        .then(() => {
          this.$toast.success("注册成功");
          this.$router.replace("/login");
        })
        .catch((err) => {
          console.log("er=>", err);
        });
    },
    handle2Agreement() {
      this.$router.push("/settings/agreement");
    },
    handle2Privacy() {
      this.$router.push("/settings/privacy");
    },
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 0 28px;
  .logo {
    width: 173px;
    margin: 34px auto;
    display: block;
  }
  .filedWrap {
    margin-bottom: 20px;
    .label {
      line-height: 20px;
      margin-bottom: 7px;
      padding-left: 16px;
    }
    .codeWrap {
      display: flex;
    }
    .field {
      height: 44px;
      line-height: 44px;
      background: #212121;
      border-radius: 27px;
      align-items: center;
      color: #fff;
      input::-webkit-input-placeholder {
        color: #666666;
      }
      &.code {
        width: 178px;
        margin-right: 7px;
        &::after {
          display: none;
        }
      }
      :global {
        .van-field__control {
          color: #fff;
        }
      }
    }
  }
  .btn {
    margin-top: 35px;
  }
  .clause {
    margin-top: 17px;
    display: flex;
    justify-content: center;
    .clauseItem {
      color: #54b9e0;
    }
  }
  .checked {
    :global {
      .van-checkbox__icon,
      .van-checkbox__label {
        font-size: 12px;
        color: #fff;
      }
      .van-icon {
        width: 12px;
        height: 12px;
        font-size: 12px;
      }
      .van-checkbox__icon--checked {
        .van-icon-success {
          border: none;
          background: url("~@/assets/img/checked.png");
          background-size: 100% 100%;
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
