// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/checked.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".index_content_uD0fE{padding:0 .74667rem}.index_content_uD0fE .index_logo_1mksC{width:4.61333rem;margin:.90667rem auto;display:block}.index_content_uD0fE .index_filedWrap_2Gu41{margin-bottom:.53333rem}.index_content_uD0fE .index_filedWrap_2Gu41 .index_label_1WYAb{line-height:.53333rem;margin-bottom:.18667rem;padding-left:.42667rem}.index_content_uD0fE .index_filedWrap_2Gu41 .index_codeWrap_t7B15{display:flex}.index_content_uD0fE .index_filedWrap_2Gu41 .index_field_19m6k{height:1.17333rem;line-height:1.17333rem;background:#212121;border-radius:.72rem;align-items:center;color:#fff}.index_content_uD0fE .index_filedWrap_2Gu41 .index_field_19m6k input::-webkit-input-placeholder{color:#666}.index_content_uD0fE .index_filedWrap_2Gu41 .index_field_19m6k.index_code_cfq3Q{width:4.74667rem;margin-right:.18667rem}.index_content_uD0fE .index_filedWrap_2Gu41 .index_field_19m6k.index_code_cfq3Q:after{display:none}.index_content_uD0fE .index_filedWrap_2Gu41 .index_field_19m6k .van-field__control{color:#fff}.index_content_uD0fE .index_btn_2LIZ3{margin-top:.93333rem}.index_content_uD0fE .index_clause_3vNnM{margin-top:.45333rem;display:flex;justify-content:center}.index_content_uD0fE .index_clause_3vNnM .index_clauseItem_1wn_S{color:#54b9e0}.index_content_uD0fE .index_checked_22R4b .van-checkbox__icon,.index_content_uD0fE .index_checked_22R4b .van-checkbox__label{font-size:.32rem;color:#fff}.index_content_uD0fE .index_checked_22R4b .van-icon{width:.32rem;height:.32rem;font-size:.32rem}.index_content_uD0fE .index_checked_22R4b .van-checkbox__icon--checked .van-icon-success{border:none;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.index_content_uD0fE .index_checked_22R4b .van-checkbox__icon--checked .van-icon-success:before{display:none}", ""]);
// Exports
exports.locals = {
	"content": "index_content_uD0fE",
	"logo": "index_logo_1mksC",
	"filedWrap": "index_filedWrap_2Gu41",
	"label": "index_label_1WYAb",
	"codeWrap": "index_codeWrap_t7B15",
	"field": "index_field_19m6k",
	"code": "index_code_cfq3Q",
	"btn": "index_btn_2LIZ3",
	"clause": "index_clause_3vNnM",
	"clauseItem": "index_clauseItem_1wn_S",
	"checked": "index_checked_22R4b"
};
module.exports = exports;
